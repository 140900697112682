import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import {
  getTrendingDestinationsForFilter,
  TrendingDestination,
} from 'home/components/TrendingDestinations/constants'
import { fetchTrendingDestinations } from 'actions/DestinationAlertsActions'
import { buildHotelUrl } from 'home/components/TrendingDestinations/utils'

export function useHotelTrendingDestinations(
  selectedFilter: App.DestinationSelectedFilters,
  currentRegionCode: string,
): [Array<TrendingDestination>, boolean] {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchTrendingDestinations())
  }, [dispatch])

  const trendingDestinations = useAppSelector(
    state => state.destination?.trendingDestinations ?? [],
  )
  const fetching = useAppSelector(
    state => state.destination?.fetchingTrendingDestinations ?? false,
  )

  const displayTrending: Array<TrendingDestination> = useMemo(() => {
    if (fetching) return []

    return trendingDestinations
      .filter(destination => destination.imageId)
      .map(destination => ({
        name: destination.primaryText,
        imageId: destination.imageId,
        url: buildHotelUrl(destination.primaryText, destination.placeId),
        destinationId: destination.placeId,
      } as TrendingDestination))
  }, [trendingDestinations, fetching])

  if (selectedFilter != 'hotel') {
    const hardCodedTrendingDestinations = getTrendingDestinationsForFilter(selectedFilter, currentRegionCode) ?? []
    return [hardCodedTrendingDestinations, false]
  }

  return [displayTrending, fetching]
}
