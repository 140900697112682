import React, { useCallback, useContext } from 'react'
import Clickable from 'components/Common/Clickable'
import { TrendingDestination } from './constants'
import BodyText from 'components/Luxkit/Typography/BodyText'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'
import { useInView } from 'react-intersection-observer'
import { EventDataKey } from 'home/pages/HomePage/useHomepageAnalytics'
import Image from 'components/Common/Image/Image'
import { rem } from 'polished'
import styled from 'styled-components'
import { mediaQueryUp } from 'components/utils/breakpoint'
import Group from 'components/utils/Group'

const Section = styled(Clickable)`
  display: grid;
  gap: ${rem(16)};
  grid-template-columns: ${rem(85)} 1fr;
  width: ${rem(280)};

  ${mediaQueryUp.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: ${rem(180)} 1fr;
    flex-direction: column;
    grid-gap: ${rem(12)};
    width: ${rem(180)};
  }
`

interface Props {
  index: number;
  trendingDestination: TrendingDestination;
}

function TrendingDestinationsTile(props: Props) {
  const { trendingDestination } = props
  const dispatchOfferListEvent = useContext(OfferListEventsContext)
  const [inViewRef] = useInView({ triggerOnce: true, threshold: 0.5 })

  const onClick = useCallback(() => {
    dispatchOfferListEvent({
      type: OfferListEvents.clientAction,
      action: 'clicked',
      subject: trendingDestination.name,
      key: EventDataKey.ClientAction,
    })
  }, [dispatchOfferListEvent, trendingDestination.name])

  return (<Section to={trendingDestination.url} target="_blank" onClick={onClick} ref={inViewRef}>
    <Image
      fit="center"
      width={180}
      height={180}
      dpr={2}
      aspectRatio="1:1"
      id={trendingDestination.imageId}
    />
    <Group direction="vertical" gap={4} horizontalAlign="start" verticalAlign="center" tabletVerticalAlign="start">
      <BodyText variant="large" weight="bold">{trendingDestination.name}</BodyText>
    </Group>
  </Section>
  )
}

export default TrendingDestinationsTile
