import React from 'react'
import TrendingDestinationsTile from './TrendingDestinationTile'
import FullWidthCarouselWithAnalytics from 'components/WithAnalytics/Carousel/FullWidthCarouselWithAnalytics'
import { mediaQueryUp } from 'components/utils/breakpoint'
import styled from 'styled-components'
import { TrendingDestination } from './constants'
import LoadingBox from 'components/Common/Loading/LoadingBox'
import { fillArray } from 'lib/array/arrayUtils'
import { rem } from 'polished'
import PillLoadingBox from 'components/Luxkit/PillLoadingBox'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'

const GridContainer = styled(FullWidthCarouselWithAnalytics)`
  > div {
    grid-template-rows: 1fr 1fr 1fr;

    ${mediaQueryUp.tablet} {
      grid-template-rows: unset;
    }
  }
`

const StyledLoadingBox = styled(LoadingBox)`
  width: ${rem(85)};
  height: ${rem(85)};


  ${mediaQueryUp.tablet} {
    width: ${rem(180)};
    height: ${rem(180)};
  }
`

const LoadingContainer = styled(VerticalSpacer)`
  display: grid;
  gap: ${rem(16)};
  grid-template-columns: ${rem(85)} 1fr;
  width: ${rem(280)};

  ${mediaQueryUp.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: ${rem(180)} 1fr;
    flex-direction: column;
    grid-gap: ${rem(12)};
    width: ${rem(180)};
  }
`

interface Props {
  trendingDestinations: Array<TrendingDestination>;
  fetchingDestinations?: boolean
}

function TrendingDestinationsCarousel(props: Props) {
  const {
    trendingDestinations,
    fetchingDestinations,
  } = props

  return <GridContainer snap="start" itemsPerArrow={4}>
    {fetchingDestinations && fillArray(11).map((index) => <LoadingContainer gap={16} key={index} >
      <StyledLoadingBox/>
      <VerticalSpacer gap={4}>
        <TextLoadingBox typography="body-large" width="8ch" />
        <PillLoadingBox width={96} />
      </VerticalSpacer>
    </LoadingContainer>)}
    {!fetchingDestinations && trendingDestinations.map((trendingDestination, index) => {
      const { name } = trendingDestination
      return (
        <TrendingDestinationsTile
          key={name}
          trendingDestination={trendingDestination}
          index={index}
        />
      )
    })}
  </GridContainer>
}

export default TrendingDestinationsCarousel
